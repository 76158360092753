import { template as template_9959f9ea8b6b473380573f72be139feb } from "@ember/template-compiler";
const FKLabel = template_9959f9ea8b6b473380573f72be139feb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
